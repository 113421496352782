<script>
export default {
  name: 'base-header',
  props: {
    baseUrl: {
      type: String,
      default: '/',
    },
    lang: String,
    active: String,
    profile: Object,
    urls: {
      type: Object,
      default: () => ({
        de: '/de/',
        en: '/en/',
        login: '/login/',
        logout: '/logout/',
        postUrl: null,
        postNext: null,
      }),
    },
    postLang: {
      type: Boolean,
      default: false,
    },
    postCsrf: {
      type: String,
      default: '',
    },
    emitNavigation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseAdminPermissions: [
        'administer_image',
      ],
      menuOrder: [
        {
          id: 1, cls: 'teaching', url: 'courses/',
        },
        {
          id: 2, cls: 'showroom', url: 'showroom/',
        },
        {
          id: 3, cls: 'portfolio', url: 'portfolio/',
        },
        {
          id: 4, cls: 'image', url: 'image/',
        },
        {
          id: 5, cls: 'image-admin', url: 'image/editing/', permissions: ['administer_image', 'edit_image'],
        },
        {
          id: 6, cls: 'cloud', url: 'cloud/',
        },
        {
          id: 7, cls: 'chat', url: 'https://chat.uni-ak.ac.at/', external: true,
        },
        {
          id: 8, cls: 'meet', url: 'https://meet.uni-ak.ac.at/', external: true,
        },
        {
          id: 9, cls: 'recherche', url: 'recherche/',
        },
        {
          id: 10, cls: 'manage', url: 'recherche/editing/', permissions: ['edit_recherche'],
        },
        {
          id: 11, cls: 'rooms-admin', url: 'rooms-admin/', permissions: ['administer_rooms', 'view_rooms'],
        },
        {
          id: 12, cls: 'rooms', url: 'courseadmin/room/', permissions: ['edit_rooms'],
        },
        {
          id: 13, cls: 'wissensbilanz', url: 'wissensbilanz/', permissions: ['administer_wb'],
        },
        {
          id: 14, cls: 'faq', url: 'faq/',
        },
      ],
      scrollPosition: null,
    };
  },
  computed: {
    menuItems() {
      const currentItems = this.menuOrder.filter(
        (el) => (
          !el.permissions
          || (
            el.permissions
            && this.profile
            && this.profile.permissions
            && Array.isArray(this.profile.permissions)
            && this.profile.permissions.some((p) => el.permissions.includes(p))
          )
        ),
      );
      if (this.active) {
        const a = this.active;
        return currentItems.filter((el) => el.cls === a)
          .concat(currentItems.filter((el) => el.cls !== a));
      }
      return currentItems;
    },
    notSupports() {
      return !('CSS' in window) || !('supports' in window.CSS);
    },
    numVisibleMenuItems() {
      if (this.profile && this.profile.permissions && Array.isArray(this.profile.permissions)) {
        return this.menuOrder.filter((el) => !el.permissions || (
          el.permissions && this.profile.permissions.some((p) => el.permissions.includes(p))
        )).length;
      }
      return this.menuOrder.filter((el) => !el.permissions).length;
    },
    profileInitials() {
      if (this.profile && this.profile.name) {
        return this.profile.name.replace(/\./g, '').replace(/\W*(\w)\w*/g, '$1').toUpperCase();
      }
      return '';
    },
  },
  watch: {
    lang: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          if (['de', 'en'].indexOf(val) !== -1) {
            this.$i18n.locale = val;
          } else {
            this.$i18n.locale = 'de';
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    emitNavigate(e, to) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('navigate', to);
    },
    handleScroll() {
      this.scrollPosition = window.pageYOffset;
    },
    nav(e, to) {
      if (this.profile) {
        if (to === 'teaching' && !['teaching', 'rooms-admin'].includes(this.active)) {
          e.preventDefault();
          e.stopPropagation();
          window.location.href = `${this.baseUrl}login/`;
        } else if (to === 'recherche' && this.active !== 'recherche') {
          e.preventDefault();
          e.stopPropagation();
          window.location.href = `${this.baseUrl}${this.menuOrder.filter((el) => el.cls === to)[0].url}login/`;
        }
      }
    },
    toggleProfile() {
      if (this.$refs.profileCheckbox) {
        this.$refs.profileCheckbox.checked = this.$refs.profileCheckbox.checked !== true;
      }
    },
    closeAll() {
      if (this.$refs.profileCheckbox) this.$refs.profileCheckbox.checked = false;
      if (this.$refs.menuCheckbox) this.$refs.menuCheckbox.checked = false;
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  directives: {
    tooltip: {
      bind(el) {
        const s = el.style;
        s.textOverflow = 'ellipsis';
        s.whiteSpace = 'nowrap';
        s.overflow = 'hidden';
        el.addEventListener('mouseover', (evt) => {
          const targetEl = evt.target;
          if (targetEl.offsetWidth < targetEl.scrollWidth) {
            targetEl.setAttribute('title', targetEl.textContent);
          } else {
            // eslint-disable-next-line no-unused-expressions
            targetEl.hasAttribute('title') && targetEl.removeAttribute('title');
          }
        });
      },
    },
  },
};
</script>

<i18n src="../locales/base-header/de.json"></i18n>
<i18n src="../locales/base-header/en.json"></i18n>

<template>
<div
  v-click-outside="closeAll"
  :class="{
    'not-supports': notSupports,
    'fixed-small': scrollPosition >= 8,
    fixed: scrollPosition >= 16
  }"
  class="navwrapper"
>
  <nav>
    <div class="base-header-ajaxloader hidden">
      <span v-for="n in 5" :key="n"></span>
    </div>
    <div id="logo"><a :href="baseUrl">base</a></div>
    <input
      v-if="profile"
      id="profile-checkbox"
      type="checkbox"
      aria-hidden="true"
      ref="profileCheckbox"
    >
    <input id="menu-checkbox" type="checkbox" aria-hidden="true" ref="menuCheckbox">
    <ul>
      <li :class="[{ active: active === 'user-preferences' }, 'profile']">
        <label
          v-if="profile"
          for="profile-checkbox"
          role="button"
          @keydown.enter="toggleProfile"
          tabindex="0"
        >
          <span class="fullname">
            {{ profile.name }} <img alt="" src="../assets/img/icon-down.svg">
          </span>
          <span class="initials">
            {{ profileInitials }} <img alt="" src="../assets/img/icon-down.svg">
          </span>
        </label>
        <a
          v-else
          v-on="emitNavigation ? { click: $event => emitNavigate($event, 'login') } : {}"
          :href="urls.login"
        >
          <span v-t="'login'"></span>
        </a>
      </li>
      <li
        v-for="item in menuItems"
        :key="item.id"
        :class="[{ active: active === item.cls }, item.cls]"
        class="navitem"
      >
        <a
          @click="nav($event, item.cls)"
          :href="item.external ? item.url : baseUrl + item.url"
          :target="item.external ? '_blank' : null"
          :rel="item.external ? 'noopener' : null"
        >
          <span>
            <img aria-hidden="true" :src="require('../assets/img/icons/' + item.cls + '.svg')">
            {{ $t(item.cls) }}
          </span>
        </a>
      </li>
      <li class="menu">
        <label for="menu-checkbox">
          <span v-t="'more'"></span>
          <span class="opened" v-t="'fewer'"></span>
        </label>
      </li>
    </ul>
    <div
      v-if="profile"
      :class="{
        l3: numVisibleMenuItems === 3,
        l4: numVisibleMenuItems === 4,
        l5: numVisibleMenuItems === 5,
      }"
      class="profile-box"
    >
      <div class="info">
        <img v-if="profile.image" class="profile-image" :src="profile.image">
        <div class="name">{{ profile.name }}</div>
        <div v-if="profile.email" v-tooltip>{{ profile.email }}</div>
        <template v-if="Array.isArray(profile.info)">
          <div v-for="(item, index) in profile.info" :key="index" v-tooltip>{{ item }}</div>
        </template>
        <div v-if="profile.showroom_id" class="showroom-id showroom">
          <a :href="baseUrl+'showroom/'+profile.showroom_id">
            <img aria-hidden="true" :src="require('../assets/img/icons/showroom.svg')">
            <span v-t="'showroom-page'"></span>
          </a>
        </div>
      </div>
      <div
        v-if="
          profile
          && profile.permissions
          && Array.isArray(profile.permissions)
          && profile.permissions.some(p => baseAdminPermissions.includes(p))"
           class="base-admin">
        <a :href="baseUrl + 'cas/da/'" v-t="'base-admin'"></a>
      </div>
      <ul>
        <li>
          <a :href="baseUrl + 'user-preferences'" v-t="'profile'"></a>
        </li>
        <li v-if="postLang && postCsrf && urls.postUrl">
          <form method="post" :action="urls.postUrl">
            <input type="hidden" name="csrfmiddlewaretoken" :value="postCsrf">
            <input v-if="urls.postNext" type="hidden" name="next" :value="urls.postNext">
            <input type="hidden" name="language" :value="lang === 'en' ? 'de' : 'en'">
            <template v-if="lang === 'de'">
              <span>DE</span>
              <button type="submit">EN</button>
            </template>
            <template v-else>
              <button type="submit">DE</button>
              <span>EN</span>
            </template>
          </form>
        </li>
        <li v-else>
          <a
            v-on="emitNavigation ? { click: $event => emitNavigate($event, 'de') } : {}"
            :class="{ active: lang === 'de' }"
            :href="urls.de"
            class="lang"
          >DE</a>
          <a
            v-on="emitNavigation ? { click: $event => emitNavigate($event, 'en') } : {}"
            :class="{ active: lang === 'en' }"
            :href="urls.en"
            class="lang"
          >EN</a>
        </li>
        <li>
          <a
            v-on="emitNavigation ? { click: $event => emitNavigate($event, 'logout') } : {}"
            :href="urls.logout"
            v-t="'logout'"
          ></a>
        </li>
      </ul>
    </div>
  </nav>
</div>
</template>

<style lang="scss" src="../scss/base-header.scss" scoped></style>
